import React from 'react'
import Helmet from 'react-helmet'
import Link from '../components/Link'
import Section from '../components/Section'
import Typography from '../components/Typography'
import Enrollment from '../components/Enrollment'
import Layout from '../layouts'
import Hero from '../components/EnrollmentHero'
import heroImage from '../images/enrollment-hero.jpg'

const ForHomes = () => {
  let isIL = false
  if (typeof window !== 'undefined') {
    const parts = window.location.hash.split('/')
    isIL = parts.includes('IL')
  }
  return (
    <>
      <Layout>
        <Helmet htmlAttributes={{ lang: 'en-US' }}>
          <title>Residential Electricity & Gas | Just Energy</title>
          <meta
            name="description"
            content="Just Energy is committed to providing you with comfort, convenience, and control through home energy solutions that meet your needs. Get competitive energy rates for your home today!"
          />
        </Helmet>
        <Hero image={heroImage}>
          <Typography variant="h1">Get Started</Typography>
          <Typography variant="h4" rendersAs="h2">
            Get the best energy rates for your home with Just Energy.
          </Typography>
        </Hero>
        <div className="enrollment-message"></div>
        {isIL && (
          <Section style={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <p>
              To find the price to compare, click{' '}
              <Link to="/locations/illinois">here.</Link>
            </p>
          </Section>
        )}
        <main>
          <Enrollment basePath="/for-homes" />
        </main>
      </Layout>
    </>
  )
}

export default ForHomes
